<template>
  <Observer name="fade" ref="section" :section="true" class="-bg-white -visibility-auto" @enter="enter" @leave="leave" root-margin="50px">
    <div ref="tagline" class="tagline" :class="{'-paused': inPause}" :data-text="item.title">
      <div v-text="item.title"></div>
    </div>
    <button type="button" class="btn-autoPlay" :class="{'-paused': inPause}" :aria-label="inPause ? 'Play' : 'Pause'" @click="inPause = !inPause"></button>
  </Observer>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'
export default {
  props: ['item', 'items', 'pagePosition', 'siblings-components'],
  name: 'tagline_horizontal_auto_scroll',
  mixins: [ReadyHook],
  data () {
    return {
      inPause: true,
      appeared: false
    }
  },
  methods: {
    enter() {
      if (!this.appeared) {
        this.$nextTick(() => {
          this.$refs.section.getRootElement().classList.remove('-visibility-auto')
        })
        this.appeared = true
      }
      if (this.inPause) this.inPause = false
    },
    leave() {
      if (!this.inPause) this.inPause = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/core";
section {
  margin: 10rem 0;
  position: relative;
}

.tagline {
  position: relative;
  height: 9.5rem;
  overflow: hidden;
  color: var(--zenith-component-tagline);
  text-shadow: 1px 0 0 var(--zenith-black), 0 1px 0 var(--zenith-black), 0 -1px 0 var(--zenith-black), -1px 0 0 var(--zenith-black), 1px 1px 0 var(--zenith-black), 1px -1px 0 var(--zenith-black), -1px 1px 0 var(--zenith-black), -1px -1px 0 var(--zenith-black);
  text-transform: uppercase;
  font-size: em(68);
  font-weight: 600;
  white-space: nowrap;
  letter-spacing: em(4, 68);

  &:after {
    position: relative;
    content: attr(data-text);
    white-space: nowrap;
    display: inline-block;
    padding-left: 4rem;
    will-change: transform;
    animation: defilement 20s infinite linear;
  }

  &.-paused {
    div {
      animation-play-state: paused;
    }
    &:after {
      animation-play-state: paused;
    }
  }

  div {
    position: relative;
    padding-left: 4rem;
    display: inline-block;
    will-change: transform;
    animation: defilement 20s infinite linear;
  }

  @include bp(max md) {
    height: 5rem;
    font-size: em(38);
    letter-spacing: em(0.64, 38);

    div, &:after {
      padding-left: 2rem;
      will-change: transform;
      animation: defilement 20s infinite linear;
    }
  }
}

.btn-autoPlay {
  bottom: -5rem;
  outline-color: var(--zenith-black);

  &:hover, &:focus {
    background: var(--zenith-black);

    &:before {
      border-left-color: var(--zenith-white);
    }

    &:after {
      border-left-color: var(--zenith-white);
      border-right-color: var(--zenith-white);
    }
  }

  &:before {
    border-left: 6px solid var(--zenith-black);
  }
  &:after {
    border-right: 2px solid var(--zenith-black);
    border-left: 2px solid var(--zenith-black);
  }
}
</style>
